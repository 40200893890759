import { graphql } from "gatsby"
import React from "react"
import { Appear, Section, SEO, SocialIcons, Text } from "../components"
import { Contact } from "../models/Contact"

interface Props {
  data: {
    page: Contact
  }
}

const ContactPage = (props: Props) => {
  const { content, seoMetaTags, title } = props.data.page

  return (
    <>
      <SEO seoMetaTags={seoMetaTags} />
      <div className="container">
        <Appear order={1}>
          <h1 className="heading-l lg:heading-xl mb-16">{title}</h1>
        </Appear>
      </div>
      {content &&
        content.map((section, i) => {
          return <Section key={section.id} section={section} order={i} />
        })}
      <div className="pb-16 lg:pb-20">
        <Appear order={20}>
          <Text header="Follow us on social">
            <SocialIcons />
          </Text>
        </Appear>
      </div>
    </>
  )
}

export const query = graphql`
  query ContactPageQuery($locale: String!) {
    page: datoCmsContact(locale: { eq: $locale }) {
      title
      content {
        __typename
        ...StudioContactFragment
        ...TextFragment
        ...Text2colFragment
      }
      seoMetaTags {
        tags
      }
    }
  }
`

export default ContactPage
